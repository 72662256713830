import s_content1 from "../../../../assets/s_content1.png";
import s_content2 from "../../../../assets/s_content2.png";
import s_content3 from "../../../../assets/s_content3.png";
import s_content4 from "../../../../assets/s_content4.png";
import s_content5 from "../../../../assets/s_content5.png";
import s_content6 from "../../../../assets/s_content6.png";

export const studentContents = [
  {
    image: s_content1,
    title: "Dersler",
    content:
      "Uygulamaya dayalı 1000'den fazla video, 4 farklı yazılım dili ile Dijital Becerileri geliştirmeye odaklı, aynı zamanda hem video izlerken soru yanıtlayacağınız, örnek yapacağınız birçok etkileşime sahip aktiviteye dayalı eğitim platformudur.",
  },
  {
    image: s_content2,
    title: "CodeLab",
    content:
      "Tüm uygulama platformlarında edindiğiniz bilgi ve tecrübeleri sıkça alıştırma yaparak bilgi kalıcılığını arttıran, çeşitli sınavlar, testler gibi uygulamaları içerisinde barındıran ölçme ve değerlendirme aşamalarını yapabileceğiniz uygulamalı alıştırma platformudur.",
  },
  {
    image: s_content3,
    title: "CodeMino",
    content:
      "CodeMino 5 farklı oyun toplam 316 aşamadan oluşan basitten zora ilerleyen oyunlaştırma modeli ile algoritma oluşturma, kodlama ve oyun tasarımının temel ilkelerini öğretmeye odaklanan, eksiksiz bir müfredat sunan oyun tabanlı eğitim platformudur.",
  },
  {
    image: s_content4,
    title: "CodeBoo",
    content:
      "CodeBoo kendinizi bilgisayar yerine koyarak komutlar çerçevesinde size verilen kodları okuyarak yorumladığınız, ok tuşları ile komutları yerine getirdiğiniz ve oyuncunuz Boo’ yu hedefine güvenlice ulaştırmaya çalıştığınız oyun tabanlı eğitim platformudur.",
  },
  {
    image: s_content5,
    title: "CodeÇizz",
    content:
      "CodeÇizz geleceğini şekillendiren bilişim yolculuğunda şekillerin sana rehberlik edebileceği çeşitli grafik tasarım şekil ve görseller oluştururken aynı zamanda kodlamayı öğrenebileceğin Algoritmik Düşünme kabiliyetin arttırmak ile birlikte kodlamayı öğretecek eğitim platformudur.",
  },
  {
    image: s_content6,
    title: "CodeArt",
    content:
      "CodeArt müzikal yeteneğin ile kodlama öğrenimini birleştiren hem müzik enstrümanları çalıp hem kodlama öğrenebileceğin çok eğlenceli, kişisel gelişim ile birlikte müzikal hobilerini geliştirebileceğin birçok aşamadan oluşan eğitim platformudur.",
  },
];
