import React from "react";
import style from "./Student.module.css";
import { studentData } from "./studentData";
import { useLocation, useNavigate } from "react-router-dom";

const Student = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const isStudentDetailRoute = location.pathname === "/platform/studentDetail";
  return (
    <div className={`${style.Student} m-0`}>
      <div className={`${style.wrapper} `}>
        <div className={`${style.top} `}>
          <span>Öğrenci</span>
        </div>
        <div className={`${style.title} `}>
          <span className={`${style.title1} `}>Bilişim Eğitimlerinin</span>{" "}
          <span className={`${style.title2} `}>En Eğlencelisi!</span>
        </div>
      <div className={style.cardContainer}>
        {studentData.map((student,index)=>(
            <div className={`${style.card1} ${index === 1 && style.centerCard}`} key={index}>
            <div className={style.cardImage}>
            <img src={student.logo} alt="logo1"/>
            </div>
            <h3 className={`${style.cardTitle} m-0`}>{student.title}</h3>
            <p className={`${style.cardDescription}`}>
              {student.description}
            </p>
          </div>
        ))}
      </div>
      </div>

      <div>
            {!isStudentDetailRoute && (
                <div 
                    className={`${style.moreDetail}`} 
                    onClick={() => navigate("/platform/studentDetail")}
                >
                    Daha fazla
                </div>
            )}
        </div>
    </div>
  );
};

export default Student;
