import React from "react";
import style from "./Section4.module.css";
import background from "../../../../assets/section4Background.png";
import star from "../../../../assets/section4Star.svg";
import { bilisimEducation } from "./bilisimEducationData";

const Section4 = () => {
  return (
    <div className={`${style.section4} my-5`}>
      <div className={`${style.wrapper} `}>
        <div className={`${style.properties} `}><span>Özellikler</span></div>
        <div className={`${style.title} `}><span>Bilişim Eğitimlerinin En Eğlencelisi!</span></div>
        <div className={`${style.propertiesTop} `}>
          {bilisimEducation.slice(0,2).map((item)=>(
              <div className={`${style.propertiesCard} ${style.propertiesCard1} `}>
              <div className={`${style.content}`}>
                <div className={`${style.imgWrapper}`}>
                  <img src={item.logo} alt="logo" />
                </div>
                <div className={`${style.detail}`}>
                  <p className={`${style.title} m-0`}>{item.title}</p>
                  <p className={`${style.desc} m-0`}>{item.description}</p>
                </div>
              </div>
              </div>
          ))}
        </div>
        <div className={`${style.propertiesBottom} `}>
        {bilisimEducation.slice(2).map((item)=>(
              <div className={`${style.propertiesCard} ${style.propertiesCard1} `}>
              <div className={`${style.content}`}>
                <div className={`${style.imgWrapper}`}>
                  <img src={item.logo} alt="logo" />
                </div>
                <div className={`${style.detail}`}>
                  <p className={`${style.title} m-0`}>{item.title}</p>
                  <p className={`${style.desc} m-0`}>{item.description}</p>
                </div>
              </div>
              </div>
          ))}
        </div>
        <div className={`${style.background}`}>
          <img className={`${style.img1}`} src={background} alt="backgroundImage" />
        </div>
        <div className={`${style.background2}`}>
          <img className={`${style.img2}`} src={star} alt="starImage" />
        </div>
      </div>
    </div>
  );
};

export default Section4;
