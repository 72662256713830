import k_content1_ from "../../../../assets/k_content1_.png";
import k_content2_ from "../../../../assets/k_content2_.png";
import k_content3_ from "../../../../assets/k_content3_.png";
import k_content4_ from "../../../../assets/k_content4_.png";

export const kurumContents = [
  {
    image: k_content1_,
    title: "Bütüncül Sınıf Yönetimi",
    content:
      "Öğretmenlerin dersler, egzersizler ve sınıflar oluşturabileceği bütüncül sınıf yönetimi özelliği eklenmiştir. Öğrencileri davet etme, ilerleme takibi yapma ve ödev atama gibi işlemleri tek bir platform üzerinden kolayca gerçekleştirilebilir.",
  },
  {
    image: k_content2_,
    title: "Kapsamlı Öğretmen Eğitim Aracı",
    content:
      "Öğretmen hesapları için özel olarak tasarlanmış kapsamlı öğretmen eğitim aracı, öğretimin etkililiğini maksimize etmeyi hedefler. Bu araç, öğretmenlere platformun sunduğu tüm özellikleri en iyi şekilde kullanmaları için rehberlik sağlar.",
  },
  {
    image: k_content3_,
    title: "Kapsamlı Yönetici Paneli",
    content:
      "Kapsamlı yönetici paneli, eğitim ekosisteminizi etkili bir şekilde yönetmek için gerekli araçları ve bilgileri sağlamak üzere tasarlanmıştır. Bu panel, platformun genel performansını izleme, verileri analiz etme ve gerektiğinde müdahale etme imkanı sunar.",
  },
  {
    image: k_content4_,
    title: "Kurum Paneli",
    content:
      "Kurum paneli, anlaşma yapılan kurumlar için özel olarak tasarlanmıştır. Bu panel üzerinden kurumlar, sınıfları yönetme, öğrenci ilerlemelerini takip etme, öğretmen atamaları yapma ve diğer birçok aksiyonu gerçekleştirme imkanına sahiptir. Bu özellik, işbirliği yapılan kurumlarla daha etkili iletişim ve yönetim sağlamayı amaçlar.",
  },
];
