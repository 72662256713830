import React from 'react';
import Section1 from './platform/components/section1/Section1';
import Student from './platform/components/student/Student';
import Content from './platform/components/contentStudent/Content';

const StudentDetail = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Section1/>
      <Student/>
      <Content/>
  </div>
  );
};

export default StudentDetail;
