import React from 'react';
import Section1 from './platform/components/section1/Section1';
import Kurumsal from './platform/components/kurumsal/Kurumsal';
import Content from './platform/components/contentKurum/Content';

const InstitutionDetail = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Section1/>
      <Kurumsal/>
      <Content/>
  </div>
  );
};

export default InstitutionDetail;
