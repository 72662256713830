import React from "react";
import style from "./Content.module.css";
import { studentContents } from "./studentContents";

const Content = () => {
  return (
    <div className={`${style.contentWrapper} m-0`}>
        <p className={`${style.mainTitle} m-0`}>Platform</p>
        <div className={`${style.Grid} m-0`}>
            {studentContents.map((item, index) => (
                <div className={`${style.Card} m-0`} key={index}>
                    <img src={item.image} alt={item.title} className={`${style.Image} m-0`} />
                    <h3 className={`${style.title} m-0`}>{item.title}</h3>
                    <p className={`${style.content} m-0`}>{item.content}</p>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Content;
