import React from 'react';
import Section1 from './platform/components/section1/Section1';
import Content from './platform/components/contentTeacher/Content';
import Teacher from './platform/components/teacher/Teacher';

const TeacherDetail = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Section1/>
      <Teacher/>
      <Content/>
  </div>
  );
};

export default TeacherDetail;
