import React from "react";
import style from "./Content.module.css";
import { kurumContents } from "./kurumContents";

const Content = () => {
  return (
    <div className={`${style.contentWrapper} m-0`}>
      <p className={`${style.mainTitle} m-0`}>
        ALL-IN-ONE K12 EĞİTİM PLATFORMU
      </p>
      <p className={`${style.description} m-0`}>
        Türkiyenin K12 düzeyinde ilki olan hepsi bir arada yazılım eğitim
        platformu TALENT14 aynı zamanda 2 yılı aşkın sürede çeşitli pedagojik
        araştırmalar neticesinde oluşan Eğitim Müfredatımızı birebir olarak
        desteklemekte ve eğitime bir uygulama platformundan çok daha fazlaca
        katkı sunabilmektedir.
      </p>
      <div className={`${style.Grid} m-0`}>
        {kurumContents.map((item, index) => (
          <div className={`${style.Card} m-0`} key={index}>
            <img
              src={item.image}
              alt={item.title}
              className={`${style.Image} m-0`}
            />
            <h3 className={`${style.title} m-0`}>{item.title}</h3>
            <p className={`${style.content} m-0`}>{item.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;
