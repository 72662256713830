import logo1 from "../../../../assets/section4Application.png";
import logo2 from "../../../../assets/section4curriculum.png";
import logo3 from "../../../../assets/section4Output.png";
import prize from "../../../../assets/section4Trophy.png";
export const bilisimEducation = [
  {
    logo: logo2,
    title: "Sertifikalı Eğitimler",
    description:
      "1800’ün üzerinde etkileşimli ve sertifikalı olarak hazırlanmış eğitimlerle teknik becerilerinizi geliştireceksiniz.",
  },
  {
    logo: prize,
    title: "Ödüller ve Yarışmalar",
    description:
      "Rozet sistemi ile bir amaç doğrultusunda disiplin kazanacaksınız. Ayrıca günlük ve haftalık yarışmalar ile gelişiminize ve geleceğinize değer katıp sürpriz ödüller kazanacaksınız.",
  },
  {
    logo: logo1,
    title: "Akıllı Veli Asistanı",
    description:
      "Çocuğunuzun aylık platform raporunu detaylı bir biçimde sms olarak ileteceğiz. Çocuğunuzun gelişimini takip edebileceksiniz.",
  },
  {
    logo: logo3,
    title: "Oyunlar ile Kodla",
    description:
      "600’ün üzerinde metin ve blok tabanlı kodlama oyunları ile hem kendinizi geliştirin hem de eğlencenin tadını çıkarın.",
  },
];
