//! Fiyatlandırma sayfasındaki alanlar burada ayarlanmıştır.
export const free = [
    "CodeMino (Sınırlı)",
    "CodeBoo (Sınırlı)",
    "Öğrenme Patikası (Sınırlı)",
    "Gelişim Paneli (Sınırlı)",
  ];
  export const premium = [
    "CodeLab",
    // "CodeÇizz",
    "Öğrenme Patikaları (İleri)",
    "Siber Güvenlik",
    "Oyun Kodlama",
    "Web Programlama",
    "Yapay Zeka",
    "CodeÇizz ile Geometri",
    "TALENT-CUP",
    "TALENT-HUB",
    // "Canlı Destek",
    // "Kariyer Danışmanı",
  ];
  export const kurumsal = [
    "Yönetici Paneli",
    "Öğretmen Paneli",
    "Öğretmen Rehberleri",
    "Ders Sunumları",
    "Öğrenci Takip Paneli",
    "Veli Bilgilendirme Sistemi",
    "Platform Eğitimi",
    "Kurum Danışmanı",
    "TALENT-CUP (Kurum İçi)",
    // "Özgün Çözümler",
    // "Gelişim Projeleri",
    // "Tüm Sistemler",
    // "Destek Temsilcisi",
  ];