import game from "../../../../assets/icon1.png";
import yapapZeka from "../../../../assets/icon3.png";
import siberLogo from "../../../../assets/icon4.png";
import mobil from "../../../../assets/mobil.png";
import robotik from "../../../../assets/robotik.png";
import icon6 from "../../../../assets/icon6.png";
export const section2cards = [
  { id:1, image: yapapZeka, title: "Yapay Zeka", description: "Makine öğrenmesi, görüntü işleme, veri bilimi gibi karar verme ve problem çözme yetenekleri kazandırmak için algoritmalar geliştirme yeteneği" },
  { id:2, image: siberLogo, title: "Siber Güvenlik", description: "Bilgisayar ağlarını ve işletim sistemlerini anlamlandırma, sistemleri koruma, saldırıları önleme ve güvenlik açıklarını tespit edip farklı bakış açıları ile kapatma yeteneği" },
  { id:3, image: robotik, title: "Robotik", description: "Robotların tasarımı, üretimi, etkin kullanımı için elektronik tasarımı yapabilme ve yazılım dillerini kullanabilme yeteneği" },
  { id:4, image: game, title: "Oyun Programlama", description: "Hayal gücünü kullanarak bilgisayar oyunlarını tasarlamak ve oluşturmak için programlama dillerini kullanabilme yeteneği" },
  { id:5, image: icon6, title: "Bulut Teknolojisi", description: "Veri depolama, işleme ve sunma için bulut tabanlı hizmetleri kullanabilme yeteneği" },
  { id:6, image: mobil, title: "Mobil Uygulama", description: "Akıllı telefonlar ve tabletler için uygulama geliştirme yeteneği" },
];
