import React from "react";
import style from "./Teacher.module.css";
import { teaacherData } from "./teaacherData";
import { useLocation, useNavigate } from "react-router-dom";

const Teacher = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const isTeacherDetailRoute = location.pathname === "/platform/teacherDetail";
  return (
    <div className={`${style.Teacher} m-0`}>
      {!isTeacherDetailRoute && <div className={`${style.line}`}></div>}
      <div className={`${style.wrapper} `}>
        <div className={`${style.top} `}>
          <span>Öğretmen</span>
        </div>
        <div className={`${style.title} `}>
          <p className={`${style.titleMain} `}>
            Türkiye’nin <span className={`${style.title1} `}>K12 </span> 
            düzeyinde yetenek odaklı <span className={`${style.title2} `}>ilk eğitim platformunu</span> öğretmenlerimizin hizmetine sunuyoruz.
          </p>{" "}
        </div>
        <div className={style.cardContainer}>
        {teaacherData.map((teacher,index)=>(
            <div className={`${style.card1} ${index === 1 && style.centerCard}`} key={index}>
            <div className={style.cardImage}>
            <img src={teacher.logo} alt="logo1"/>
            </div>
            <h3 className={`${style.cardTitle} m-0`}>{teacher.title}</h3>
            <p className={`${style.cardDescription}`}>
              {teacher.description}
            </p>
          </div>
        ))}
        </div>
      </div>

      <div>
            {!isTeacherDetailRoute && (
                <div 
                    className={`${style.moreDetail}`} 
                    onClick={() => navigate("/platform/teacherDetail")}
                >
                    Daha fazla
                </div>
            )}
        </div>
    </div>
  );
};

export default Teacher;
