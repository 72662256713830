import React, { useEffect, useState } from "react";
import style from "./Section6.module.css";
import partnerPino from "../../../../assets/partnerPino.png";
import partnerMorMaker from "../../../../assets/partnerMorMaker.png";
import partnerRKA from "../../../../assets/partnerRKA.png";
import partnerABB from "../../../../assets/partnerABB.png";
import partnerBeylikduzu from "../../../../assets/partnerBeylikduzu.png";
import partnerBilimKoleji from "../../../../assets/partnerBilimKoleji.png";
import partnerBS from "../../../../assets/partnerBS.png";
import partnerbursabuyukkolej from "../../../../assets/partnerbursabuyukkolej.png";
import partnerBuyukDegisim from "../../../../assets/partnerBuyukDegisim.png";
import partnerdoku from "../../../../assets/partnerdoku.png";
import partnerDunyaCocuk from "../../../../assets/partnerDunyaCocuk.png";
import partnerErdem from "../../../../assets/partnerErdem.png";
import partnerMEB from "../../../../assets/partnerMEB.png";
import partnerneomar from "../../../../assets/partnerneomar.png";
import partnernidaKoc from "../../../../assets/partnernidaKoc.png";
import partnerTeknolojikent from "../../../../assets/partnerTeknolojikent.png";
import partnerVizyon from "../../../../assets/partnerVizyon2.png";

const Section6 = () => {
  const partners = [partnerPino, partnerMorMaker, partnerRKA, partnerABB, partnerBeylikduzu, partnerBilimKoleji, partnerBS, partnerbursabuyukkolej, partnerBuyukDegisim, partnerdoku, partnerDunyaCocuk, partnerErdem, partnerMEB, partnerneomar, partnernidaKoc, partnerTeknolojikent, partnerVizyon];
  const [repeatCount, setRepeatCount] = useState(1);

  useEffect(() => {
    const updateRepeatCount = () => {
      const partnersWidth = partners.length * 150;
      const containerWidth = window.innerWidth;
      const numberOfRepeats = Math.ceil(containerWidth / partnersWidth) + 1;

      setRepeatCount(numberOfRepeats * 10); 
    };

    updateRepeatCount();
    window.addEventListener("resize", updateRepeatCount);

    return () => window.removeEventListener("resize", updateRepeatCount);
  }, []);
  return (
    <div className={`${style.section6} my-5`}>
      <div className={`${style.wrapper} `}>
        <p className={`${style.title} m-0`}>İş Birliklerimiz</p>
        <p className={`${style.description} m-0`}>
          Sizinle birlikte başarılı projeler yapmak ve büyümek için buradayız.
          İş ortaklarımızla güç birliği yaparak, yenilikçi çözümler üretiyor ve
          karşılıklı başarıyı hedefliyoruz.
        </p>
        <div className={`${style.partners} `}>
        <div className={style.marquee}>
        <div className={style.track}>
        {[...Array(repeatCount)].flatMap(() =>partners.map((partner, index) => (
          <div key={`${index}-${partner}`}>
            <img className={`${style.partnerImg} `} src={partner} alt={`partner-${index}`} />
          </div>
        )))}
      </div>
      </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
