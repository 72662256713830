import React from "react";
import style from "./Kurumsal.module.css";
import { kurumsalData } from "./kurumsalData";
import { useLocation, useNavigate } from "react-router-dom";

const Kurumsal = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const isKurumDetailRoute = location.pathname === "/platform/institutionDetail";
  return (
    <div className={`${style.Kurumsal} m-0`}>
      {!isKurumDetailRoute && <div className={`${style.line}`}></div>}
      <div className={`${style.wrapper} `}>
        <div className={`${style.top} `}>
          <span>Kurumsal</span>
        </div>
        <div className={`${style.title} `}>
          <p className={`${style.titleMain} `}>
            Türkiye’nin <span className={`${style.title1} `}>K12 </span>
            düzeyinde ölçeklenebilir başarı analizi ve hibrit odaklı {" "}
            <span className={`${style.title2} `}>
              ilk eğitim platformumuzu
            </span>{" "}
            kurumlarımızın hizmetine sunuyoruz.
          </p>{" "}
        </div>
        <div className={style.cardContainer}>
        {kurumsalData.map((kurum,index)=>(
            <div className={`${style.card1} ${index === 1 && style.centerCard}`} key={index}>
            <div className={style.cardImage}>
            <img src={kurum.logo} alt="logo1"/>
            </div>
            <h3 className={`${style.cardTitle} m-0`}>{kurum.title}</h3>
            <p className={`${style.cardDescription}`}>
              {kurum.description}
            </p>
          </div>
        ))}
        </div>
      </div>

      <div>
            {!isKurumDetailRoute && (
                <div 
                    className={`${style.moreDetail}`} 
                    onClick={() => navigate("/platform/institutionDetail")}
                >
                    Daha fazla
                </div>
            )}
        </div>
    </div>
  );
};

export default Kurumsal;
