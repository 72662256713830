import React, { useState, useEffect, useRef } from 'react';
import style from "./Section5.module.css";
import leftBg from "../../../../assets/section5LeftBg.svg";
import rightBg from "../../../../assets/section5RightBg.svg";
import CountUp from 'react-countup';

const Section5 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // %50 görünürlükten itibaren
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <div ref={sectionRef} className={`${style.section5} my-5`}>
      <div className={`${style.wrapper} `}>
        <div className={`${style.leftImgWrapper}`}>
          <img src={leftBg} alt="leftBg" />
        </div>
        <div className={`${style.rightImgWrapper}`}>
          <img src={rightBg} alt="rightBg" />
        </div>
        <div className={`${style.counter}`}>
          <div className={`${style.content}`}>
            <div className={`${style.box} ${style.box1}`}>
              <div className={`${style.title}`}>
                <p className={`${style.title1} m-0`}>{isVisible ? <CountUp end={22000} duration={2.5} separator="," /> : 0}</p>
                <p className={`${style.title2} m-0`}>+</p>
              </div>
              <p className={`${style.desc} m-0`}>Kayıtlı Kullanıcı</p>
            </div>
            <div className={`${style.box} ${style.box2}`}>
              <div className={`${style.title}`}>
                <p className={`${style.title1} m-0`}>100</p>
                <p className={`${style.title2} m-0`}>%</p>
              </div>
              <p className={`${style.desc} m-0`}>Etkin Destek Hizmeti</p>
            </div>
            <div className={`${style.box} ${style.box3}`}>
              <div className={`${style.title}`}>
                <p className={`${style.title1} m-0`}>100</p>
                <p className={`${style.title2} m-0`}>%</p>
              </div>
              <p className={`${style.desc} m-0`}>Müşteri Memnuniyeti</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
