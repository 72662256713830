import t_content1 from "../../../../assets/t_content1.png";
import t_content2 from "../../../../assets/t_content2.png";
import t_content3 from "../../../../assets/t_content3.png";
import t_content4 from "../../../../assets/t_content4.png";
import t_content5 from "../../../../assets/t_content5.png";
import t_content6 from "../../../../assets/t_content6.png";

export const teacherContents = [
  {
    image: t_content1,
    title: "2000+ Etkileşimli İçerik",
    content:
      "Öğretmenlerimiz uygulamaya dayalı 2000'den fazla etkileşimli video içeriğe Premium düzeyde ücretsiz olarak erişebilir ve kendi etkileşimli içeriklerini platform üzerinde H5P özelliğinde oluşturabilir ve düzenleyebilir.",
  },
  {
    image: t_content2,
    title: "Sınıf Yönetimi",
    content:
      "Öğretmen panelinizde eğitim verdiğiniz sınıf ve öğrenci gruplarını oluşturabilir. Sınıflara ait eğitim durumlarını, ilerleme süreçlerini, sınıf analizlerini detaylı olarak buradan inceleyebilir, bu süreçleri raporlayabilirsiniz.",
  },
  {
    image: t_content3,
    title: "Ödevlendirme Ve Kontroller",
    content:
      "Sınıflarınıza ve öğrenci gruplarınıza istediğiniz kadar ödev tanımlayabilir, bu ödevlerin kotrolünü panel üzerinden kolayca sağlayabilirsiniz. Ödevlendirmelerinizde sistemde tanımlı Etkileşimli İçerikleri, Alıştırmaları, Oyun Aşamalarını da kullanabilirsiniz.",
  },
  {
    image: t_content4,
    title: "Canlı Ders",
    content:
      "Online yapacağınız dersleri istediğiniz zaman ve aralık tanımlamalarıyla birlikte sisteme girebilir, bu online dersleri sınıflara ve öğrencilere atamalarını kolayca yapabilirsiniz. Zoom, Google Meet, Webex vb. tüm platform linklerini sistem üzerinde tanımlayabilirsiniz.",
  },
  {
    image: t_content5,
    title: "Alıştırmalar",
    content:
      "Konu anlatımı sonrasında sınıf ortamında kullanımınızda ya da tekrar amaçlı ödevlendirmelerinizde CodeLab platformunda tanımlı birçok alıştırmayı kullanabileceğiniz gibi kendi alıştırmalarınızı da sistem üzerinde oluşturabilirsiniz.",
  },
  {
    image: t_content6,
    title: "Veli Bilgilendirme",
    content:
      "Sistem üzerinde tanımlı olan öğrenci velilerine aylık olarak sistem tarafından otomatik rapor sms'i gönderilerek öğrencinin aylık eğitim durumu, ödev durumu gibi veli bilgilendirmeleri yapılabilir.",
  },
];
